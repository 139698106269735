import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { Grid, Row } from 'react-flexbox-grid'
import ImageGallery from 'react-image-gallery'
import upperCase from 'lodash/upperCase'
import { useIntl } from 'gatsby-plugin-intl'

import Layout from '../components/Layout'
import 'react-image-gallery/styles/css/image-gallery.css'
import '../components/image-gallery-custom.css'
import { TABLET_MIN_BREAKPOINT } from '../constants/breakpoints'

const GalleryWrapper = styled.div`
  width: 100%;
`

const IntroBoxWrapper = styled.div`
  width: 100%;
  padding: 3rem 0 0 0;
  text-align: center;

  h1 {
    padding-bottom: 1rem;
  }
  h2:not(:last-child) {
    padding-bottom: 0.5rem;
  }
  h3 {
    font-style: italic;
    > span {
      display: block;
      font-style: normal;
      font-size: 0.8rem;

      @media screen and (${TABLET_MIN_BREAKPOINT}) {
        display: inline;
      }
    }
  }
`

export const IndexPageTemplate = ({ galleryImages, title, headings }) => {
  const { formatMessage } = useIntl()
  return (
    <Grid fluid style={{ width: '100%' }}>
      <Row>
        <GalleryWrapper>
          <ImageGallery
            // TODO: lazyload + leverage gatsby image processing here.
            items={galleryImages.map(({ image }) => ({
              original: image.childImageSharp.fluid.src,
            }))}
            showThumbnails={false}
            showPlayButton={false}
            showNav={false}
            showFullscreenButton={false}
            slideInterval={4000}
            slideDuration={1500}
            autoPlay
          />
        </GalleryWrapper>
      </Row>
      <Row>
        <IntroBoxWrapper>
          <h1 className="is-size-2 is-size-4-mobile has-optima-font">
            {upperCase(formatMessage({ id: 'home.title' }))}
          </h1>
          <h2>{formatMessage({ id: 'home.heading1' })}</h2>
          <h3>
            {formatMessage({ id: 'home.heading2' })}
            <span> - {formatMessage({ id: 'home.heading3' })}</span>
          </h3>
        </IntroBoxWrapper>
      </Row>
    </Grid>
  )
}

IndexPageTemplate.propTypes = {
  galleryImages: PropTypes.array,
  title: PropTypes.string,
  headings: PropTypes.array,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <Layout>
      <IndexPageTemplate galleryImages={frontmatter.galleryImages} />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        galleryImages {
          image {
            childImageSharp {
              fluid(maxWidth: 1600, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
